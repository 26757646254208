html {
  height: 100%;
  overflow: hidden;
}
body {
  background: url("d4d7667e20c58a1268af9bacd88769d5.jpg") no-repeat center center fixed;
  background-size: cover;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0;
  padding: 0;
  position: relative;
}
* {
  perspective: 100vh;
}
